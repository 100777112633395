<template>
  <section class="auth-login" @click="closeModal">
    <div class="auth-login__vector-right">
        <img src="../../assets/images/vector-right.svg" alt="Background" />
    </div>
    <div class="auth-login__container">
      <div class="auth-login__vector-left">
        <img src="../../assets/images/vector-left.svg" alt="Background" />
      </div>
      <div class="auth-login__logo">
        <img src="../../assets/images/logo-inspira.svg" alt="Logo Inspirali Medicina" />
      </div>
      <div class="auth-login__logo-medium">
        <img src="../../assets/images/logo-inspira-medium.svg" alt="Logo Inspirali Medicina" />
      </div>
      <div class="auth-login__logo-small">
        <img src="../../assets/images/logo-inspira-small.svg" alt="Logo Inspirali Medicina" />
      </div>
      <section class="auth-login__form">
        <template v-if="loading">
          <div class="auth-login__form-loading">
            <div class="loading-spinner"></div>
          </div>
        </template>
        <template v-else>
          <template v-if="canLogin">
            <auth-login-form
              :canCreateAccount="canCreateAccount"
              @show="onShow"
            />
          </template>
          <template v-else>
            <auth-login-form-unavailable
              :message="messageFormUnavailable"
              @show="onShow"
            />
          </template>
        </template>
      </section>
    </div>
    <auth-modal
      v-if="showModal"
      :login="canLogin"
      :list="institutionalEducationsList"
      @close="onClose"
    />
  </section>
</template>

<script>
import AuthModal from "./AuthModal.vue";
import AuthLoginForm from "./form/AuthLoginForm.vue";
import AuthLoginFormUnavailable from "./form/AuthLoginFormUnavailable.vue";
import AuthService from "../../services/auth/AuthService";

export default {
  name: "AuthLogin",
  components: {
    "auth-login-form": AuthLoginForm,
    "auth-login-form-unavailable": AuthLoginFormUnavailable,
    "auth-modal": AuthModal,
  },
  data() {
    return {
      loading: false,
      canLogin: false,
      showModal: false,
      canCreateAccount: false,
      messageFormUnavailable: "",
      institutionalEducationsList: []
    }
  },
  mounted() {
    this.fetchLoginConfiguration();
  },
  methods: {
    async fetchLoginConfiguration() {
      this.loading = true;
      await AuthService.getLoginConfiguration()
        .then(({ data }) => {
          this.canLogin = data.canLogin;
          this.canCreateAccount = data.canCreateAccount;
          this.messageFormUnavailable = data.message;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async onShow(showResultLink) {
      let params = ''

      if (showResultLink) {
        params = {
          showResultLink: true
        }
      }

      await AuthService.getInstitutionalEducations(params)
        .then(({ data }) => {
          this.institutionalEducationsList = data;
          this.showModal = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onClose() {
      this.showModal = false;
    },
    closeModal() {
      if (this.showModal) {
        this.onClose();
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.auth-login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #1F3268 0%, #6089D6 100%);

  &__container {
    position: relative;
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;
  }

  &__vector-left {
    display: none;
  }

  &__logo {
    display: none;
  }

  &__logo-small {
    display: block;
    position: absolute;
    top: 591px;
    left: 0;
    right: 0;
    text-align: center;
    
    img {
      width: 133px;
      height: 33px;
    }
  }

  &__logo-medium {
    display: block;
    position: absolute;
    top: 21px;
    left: 0;
    right: 0;
    text-align: center;
  }

  &__vector-right {
    display: none;
  }
  
  &__title {
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    margin-bottom: 60px;
  }

  &__form {
    position: absolute; 
    width: 312px;
    height: 500px;
    top: 70px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 16px;
    padding: 32px;
    background-color: #ffffff;
  }

  &__form-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

@media screen and (min-width: 414px) {
  .auth-login {
    &__logo-medium {
      top: 79px;
    }

    &__form {
      width: 348px;
      top: 187px;
    }

    &__logo-small {
      top: 789px;

      img {
        width: 142px;
        height: 30px;
      }
    }
  }
}

@media screen and (min-width: 1080px) {
  .auth-login {
    &__vector-right {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
    }

    &__vector-left {
      display: block;
      position: absolute;
      top: -330px;
      left: -596px;
    }

    &__logo-medium {
      top: 20px;
    }

    &__logo-small {
      top: 591px;
    }

    &__form {
      width: 312px;
      height: 500px;
      top: 70px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}

@media screen and (min-width: 1366px) {
  .auth-login {
    &__logo {
      display: block;
      position: absolute;
      top: 310px;
      left: 133px;
    }

    &__logo-medium {
      display: none;
    }

    &__logo-small {
      position: absolute;
      top: 383px;
      left: -234px;

      img {
        width: 174px;
        height: 40px;
      }
    }

    &__form {
      top: 123px;
      right: -620px;
      width: 364px;
      height: 522px;
    }
  }
}

.loading-spinner {
  margin-left: 8px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-top: 2px solid #000000;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
