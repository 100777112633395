var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-modal"},[_c('div',{staticClass:"auth-modal__card",on:{"click":function($event){$event.stopPropagation();return _vm.onClick.apply(null, arguments)}}},[_c('div',{staticClass:"auth-modal__header"},[_c('div',{staticClass:"auth-modal__header-icons"},[_vm._m(0),_c('div',{staticClass:"auth-modal__header-icons-close",on:{"click":_vm.closeModal}},[_c('img',{attrs:{"src":require("../../assets/icons/icon-close.svg"),"width":"44","height":"44","alt":"Fechar"}})])]),(_vm.login)?_c('div',{staticClass:"auth-modal__header-title"},[_vm._m(1)]):_c('div',{staticClass:"auth-modal__header-title-candidate"},[_vm._m(2)])]),_c('div',{staticClass:"auth-modal__content"},[_c('a',{attrs:{"href":_vm.linkInstitutionalEducations('AGES'),"target":"_blank"}},[_vm._m(3)]),_c('a',{attrs:{"href":_vm.linkInstitutionalEducations('UNIBH'),"target":"_blank"}},[_vm._m(4)]),_c('a',{attrs:{"href":_vm.linkInstitutionalEducations('UNISUL'),"target":"_blank"}},[_vm._m(5)]),_c('a',{attrs:{"href":_vm.linkInstitutionalEducations('FASEH'),"target":"_blank"}},[_vm._m(6)]),_c('a',{attrs:{"href":_vm.linkInstitutionalEducations('UNIFACS'),"target":"_blank"}},[_vm._m(7)]),_c('a',{attrs:{"href":_vm.linkInstitutionalEducations('ANHEMBI'),"target":"_blank"}},[_vm._m(8)]),_c('a',{attrs:{"href":_vm.linkInstitutionalEducations('SAO-JUDAS'),"target":"_blank"}},[_vm._m(9)]),_c('a',{attrs:{"href":_vm.linkInstitutionalEducations('UNIFG'),"target":"_blank"}},[_vm._m(10)]),_c('a',{attrs:{"href":_vm.linkInstitutionalEducations('UNP'),"target":"_blank"}},[_vm._m(11)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../assets/icons/icon-circle.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('b',[_vm._v("Entre em contato")]),_vm._v(" com a instituição:")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('b',[_vm._v("Entre na Área do Candidato")]),_vm._v(" da instituição:")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-modal__content-logo-ages"},[_c('img',{attrs:{"src":require("../../assets/images/logo-ages.png"),"alt":"ages"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-modal__content-logo-unibh"},[_c('img',{attrs:{"src":require("../../assets/images/logo-unibh.png"),"alt":"unibh"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-modal__content-logo-unisul"},[_c('img',{attrs:{"src":require("../../assets/images/logo-unisul.png"),"alt":"unisul"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-modal__content-logo-faseh"},[_c('img',{attrs:{"src":require("../../assets/images/logo-faseh.png"),"alt":"faseh"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-modal__content-logo-unifacs"},[_c('img',{attrs:{"src":require("../../assets/images/logo-unifacs.png"),"alt":"unifacs"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-modal__content-logo-anhembi"},[_c('img',{attrs:{"src":require("../../assets/images/logo-anhembi.png"),"alt":"anhembi"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-modal__content-logo-sao-judas"},[_c('img',{attrs:{"src":require("../../assets/images/logo-sao-judas.png"),"alt":"são judas"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-modal__content-logo-unifg"},[_c('img',{attrs:{"src":require("../../assets/images/logo-unifg.png"),"alt":"unifg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-modal__content-logo-unp"},[_c('img',{attrs:{"src":require("../../assets/images/logo-unp.png"),"alt":"unp"}})])
}]

export { render, staticRenderFns }