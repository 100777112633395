<template>
  <section class="register-view">
    <div class="register-view__logo-top">
      <img src="../assets/images/logo-inspira-base.svg" alt="Logo Inspira Medicina" />
    </div>
    <div class="register-view__content">
      <div class="register-view__card">
        <register-form />
      </div>
    </div>
    <div class="register-view__logo-footer">
      <img src="../assets/images/logo-inspira-small.svg" width="124" height="28" />
    </div>
  </section>
</template>

<script>
import RegisterForm from "@/components/register/RegisterForm.vue";
export default {
  name: "RegisterView",
  components: {
    "register-form": RegisterForm,
  },
}
</script>

<style lang="scss" scoped>
.register-view {
  background: linear-gradient(90deg, #1F3268 0%, #6089D6 100%);

  &__logo-top {
    padding-top: 24px;
    display: flex;
    justify-content: center;
  }

  &__content {
    display: flex;
    justify-content: center;
  }

  &__card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 19px;
    min-width: 312px;
    margin-left: 24px;
    margin-right: 24px;
  }

  &__logo-footer {
    display: flex;
    justify-content: center;
    padding-top: 22px;
    padding-bottom: 18px;
  }
}

@media screen and (min-width: 414px) {
  .register-view {
    &__card {
      width: 348px;
    }
  }
}

@media screen and (min-width: 1080px) {
  .register-view {
    height: 100vh;
  
    &__card {
      width: 759px;
    }
  }
}
</style>