<template>
  <div class="dropdown" ref="dropdown" @click="toggleDropdown">
    <span class="dropdown-button">
      <span>
        {{ selectedItem ? selectedItem.label : 'Selecionar' }}
      </span>
      <span v-if="loading" class="loading-spinner"></span>
      <img
        v-if="!loading"
        src="../assets/icons/icon-arrow.svg"
        :class="{ 'dropdown-icon--active': isOpen }"
      />
    </span>
    <ul v-if="isOpen && !loading" class="dropdown-content">
      <li
        v-for="item in items"
        :class="{ 'active': selectedItem && selectedItem.id === item.id}"
        :key="item.id"
        @click.stop="selectItem(item)"
      >
        {{ item.label }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    items: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      default: () => ({ id: null, label: 'Selecionar' })
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false,
      selectedItem: this.value
    };
  },
  watch: {
    value(newValue) {
      this.selectedItem = newValue;
    }
  },
  methods: {
    toggleDropdown() {
      if (this.disabled) return;
      this.isOpen = !this.isOpen;
    },
    selectItem(item) {
      this.selectedItem = item;
      this.$emit('input', item);
      this.isOpen = false;
    },
    handleClickOutside(event) {
      if (!this.$refs.dropdown.contains(event.target)) {
        this.isOpen = false;
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  }
}
</script>

<style scoped>
.dropdown {
  width: 100%;
  position: relative;
  display: inline-block;
  cursor: pointer;
  user-select: none;
}

.dropdown-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background-color: #ffffff;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  position: relative;
  font-size: 16px;
}

.dropdown-icon--active {
  transform: rotate(180deg);
}

.loading-spinner {
  margin-left: 8px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-top: 2px solid #000000;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.dropdown-content {
  display: block;
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 3px 25px 0px #00000026;
  z-index: 1;
  border-radius: 5px;
  padding: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  overflow-y: auto;
  max-height: 156px;
  font-size: 14px !important;
}

.dropdown-content::before {
  content: "";
  border-bottom: 8px solid #ffffff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  top: -8px;
  right: 13px;
  transform: inherit;
  position: absolute;
  box-shadow: 0px 3px 25px 0px #00000026;
}

.dropdown-content li {
  padding: 10px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content li:hover {
  color: #2F54D3;
  background-color: #e0e6f9;
}

.dropdown-content li.active {
  color: #2F54D3;
  background-color: #e0e6f9;
}
</style>
