var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"auth-login",on:{"click":_vm.closeModal}},[_vm._m(0),_c('div',{staticClass:"auth-login__container"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('section',{staticClass:"auth-login__form"},[(_vm.loading)?[_vm._m(5)]:[(_vm.canLogin)?[_c('auth-login-form',{attrs:{"canCreateAccount":_vm.canCreateAccount},on:{"show":_vm.onShow}})]:[_c('auth-login-form-unavailable',{attrs:{"message":_vm.messageFormUnavailable},on:{"show":_vm.onShow}})]]],2)]),(_vm.showModal)?_c('auth-modal',{attrs:{"login":_vm.canLogin,"list":_vm.institutionalEducationsList},on:{"close":_vm.onClose}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-login__vector-right"},[_c('img',{attrs:{"src":require("../../assets/images/vector-right.svg"),"alt":"Background"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-login__vector-left"},[_c('img',{attrs:{"src":require("../../assets/images/vector-left.svg"),"alt":"Background"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-login__logo"},[_c('img',{attrs:{"src":require("../../assets/images/logo-inspira.svg"),"alt":"Logo Inspirali Medicina"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-login__logo-medium"},[_c('img',{attrs:{"src":require("../../assets/images/logo-inspira-medium.svg"),"alt":"Logo Inspirali Medicina"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-login__logo-small"},[_c('img',{attrs:{"src":require("../../assets/images/logo-inspira-small.svg"),"alt":"Logo Inspirali Medicina"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-login__form-loading"},[_c('div',{staticClass:"loading-spinner"})])
}]

export { render, staticRenderFns }