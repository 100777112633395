<template>
  <div>
    <auth-login v-if="!error"/>
    <unavailable-service v-else />
  </div>
</template>

<script>
import AuthLogin from "@/components/auth/AuthLogin.vue";
import UnavailableServiceView from "@/pages/UnavailableServiceView";
import { mapGetters } from 'vuex'

export default {
  name: "HomeView",
  components: {
    "auth-login": AuthLogin,
    "unavailable-service": UnavailableServiceView
  },
  computed: {
    ...mapGetters({
      error: "auth/getLoginError"
    })
  }
};
</script>

<style lang="scss" scoped></style>
