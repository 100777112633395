import api from '../api';

class RegisterService {
  async getStates() {
    const { data } = await api.get("/api/SchoolUser/GetStates");
    return data;
  }

  async getCities(sigla) {
    const { data } = await api.get(`/api/SchoolUser/GetCities?sigla=${sigla}`);
    return data;
  }

  async createUser(params) {
    const { data } = await api.post('/api/SchoolUser/', params);
    return data;
  }
}

export default new RegisterService;