import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import { store } from "./store";
import { routes } from "./router/routes.js";
import VueTheMask from 'vue-the-mask'
import Vuelidate from 'vuelidate'
import "@/assets/scss/app.scss";

Vue.use(VueRouter);
Vue.use(VueTheMask)
Vue.use(Vuelidate)
Vue.config.productionTip = false

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

new Vue({
    render: h => h(App),
    store: store,
    router: router,
}).$mount('#app')
