<template>
  <div class="auth-modal">
    <div class="auth-modal__card" @click.stop="onClick">
      <div class="auth-modal__header">
        <div class="auth-modal__header-icons">
          <div><img src="../../assets/icons/icon-circle.svg" /></div>
          <div class="auth-modal__header-icons-close" @click="closeModal">
            <img
              src="../../assets/icons/icon-close.svg"
              width="44"
              height="44"
              alt="Fechar"
            />
          </div>
        </div>
        <div v-if="login" class="auth-modal__header-title">
          <span ><b>Entre em contato</b> com a instituição:</span>
        </div>
        <div v-else class="auth-modal__header-title-candidate">
          <span ><b>Entre na Área do Candidato</b> da instituição:</span>
        </div>
      </div>
      <div class="auth-modal__content">
        <a :href="linkInstitutionalEducations('AGES')" target="_blank">
          <div class="auth-modal__content-logo-ages">
            <img src="../../assets/images/logo-ages.png" alt="ages" />
          </div>
        </a>
        <a :href="linkInstitutionalEducations('UNIBH')" target="_blank">
          <div class="auth-modal__content-logo-unibh">
            <img src="../../assets/images/logo-unibh.png" alt="unibh" />
          </div>
        </a>
        <a :href="linkInstitutionalEducations('UNISUL')" target="_blank">
          <div class="auth-modal__content-logo-unisul">
            <img src="../../assets/images/logo-unisul.png" alt="unisul" />
          </div>
        </a>
        <a :href="linkInstitutionalEducations('FASEH')" target="_blank">
          <div class="auth-modal__content-logo-faseh">
            <img src="../../assets/images/logo-faseh.png" alt="faseh" />
          </div>
        </a>
        <a :href="linkInstitutionalEducations('UNIFACS')" target="_blank">
          <div class="auth-modal__content-logo-unifacs">
            <img src="../../assets/images/logo-unifacs.png" alt="unifacs" />
          </div>
        </a>
        <a :href="linkInstitutionalEducations('ANHEMBI')" target="_blank">
          <div class="auth-modal__content-logo-anhembi">
            <img src="../../assets/images/logo-anhembi.png" alt="anhembi" />
          </div>
        </a>
        <a :href="linkInstitutionalEducations('SAO-JUDAS')" target="_blank">
          <div class="auth-modal__content-logo-sao-judas">
            <img src="../../assets/images/logo-sao-judas.png" alt="são judas" />
          </div>
        </a>
        <a :href="linkInstitutionalEducations('UNIFG')" target="_blank">
          <div class="auth-modal__content-logo-unifg">
            <img src="../../assets/images/logo-unifg.png" alt="unifg" />
          </div>
        </a>
        <a :href="linkInstitutionalEducations('UNP')" target="_blank">
          <div class="auth-modal__content-logo-unp">
            <img src="../../assets/images/logo-unp.png" alt="unp" />
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>

const institutionalEducations = [
  {
    name: "AGES",
    id: "7cc6b19d-992b-4a2e-8151-f295304992ff",
  },
  {
    name: "UNIBH",
    id: "6e311370-5b67-42d7-81af-f3a16a6ca605",
  },
  {
    name: "UNISUL",
    id: "db2d7e3d-4ccd-4344-9b36-76f1dcaedd81",
  },
  {
    name: "FASEH",
    id: "b0c0eea8-a113-4c3b-9c65-4572434c29e2",
  },
  {
    name: "UNIFACS",
    id: "86a646f2-76fb-41e9-8948-04a32b458e6e",
  },
  {
    name: "ANHEMBI",
    id: "53394deb-0eaf-47f6-8d67-1d9318c6d5c6",
  },
  {
    name: "SAO-JUDAS",
    id: "28186134-442e-461c-ae05-ed7de9687ea9",
  },
  {
    name: "UNIFG",
    id: "8c0c0219-cd6e-4677-833f-7a16d88db55c",
  },
  {
    name: "UNP",
    id: "dbfd444f-f6b5-4ab1-900c-1332b8888014",
  },
];

export default {
  name: "AuthModal",
  props: {
    login: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["close"],
  data() {
    return {
      institutionalEducations
    }
  },
  computed: {
    institutionalEducationsList() {
      return this.list;
    },
  }, 
  methods: {
    closeModal() {
      this.$emit("close");
    },
    onClick() {
      return;
    },
    findIdByName(name, array) {
      const foundItem = array.find(item => item.name === name);
      return foundItem ? foundItem.id : null;
    },
    linkInstitutionalEducations(id) {
      const idInstitutionalEducations = this.findIdByName(id, institutionalEducations);
      const linkInstitutionalEducations = this.institutionalEducationsList.find(item => item.id === idInstitutionalEducations);
      return linkInstitutionalEducations ? (linkInstitutionalEducations.resultLink || linkInstitutionalEducations.institutionalLink) : null;
    }
  },
};
</script>

<style lang="scss" scoped>
.auth-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);

  &__card {
    position: absolute;
    width: 312px;
    height: 582px;
    top: 32px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
    padding: 24px;
    border-radius: 5px;
    background-color: #ffffff;
  }

  &__header {
    margin-top: 12px;

    &-icons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: -16px;

      &-close {
        cursor: pointer;
        margin-right: -12px;
      }

      &-close:hover {
        img {
          content: url("../../assets/icons/icon-close-active.svg");
        }
      }
    }

    &-title {
      display: block;
      color: rgba(78, 97, 172, 1);
      font-size: 22px;
      width: 228px;
    }

    &-title-candidate {
      display: block;
      color: rgba(78, 97, 172, 1);
      font-size: 22px;
      width: 248px;
    }
  }

  &__content {
    &-logo-ages {
      position: absolute;
      top: 152px;
      left: 58px;

      img {
        width: 60%;
      }
    }

    &-logo-unibh {
      position: absolute;
      top: 230px;
      left: 178px;
      margin-left: 10px;

      img {
        width: 80%;
        height: auto;
      }
    }

    &-logo-unisul {
      position: absolute;
      top: 401px;
      left: 23px;

      img {
        width: 60%;
        height: auto;
      }
    }

    &-logo-faseh {
      position: absolute;
      top: 152px;
      left: 174px;

      img {
        width: 60%;
        height: auto;
      }
    }

    &-logo-unifacs {
      position: absolute;
      top: 312px;
      left: 18px;

      img {
        width: 60%;
        height: auto;
      }
    }

    &-logo-anhembi {
      position: absolute;
      top: 403px;
      left: 138px;
      margin-right: 20px;

      img {
        width: 100%;
        height: auto;
      }
    }

    &-logo-sao-judas {
      position: absolute;
      top: 230px;
      right: 52px;

      img {
        width: 60%;
        height: auto;
      }
    }

    &-logo-unifg {
      position: absolute;
      top: 312px;
      right: -98px;

      img {
        width: 60%;
        height: auto;
      }
    }

    &-logo-unp {
      position: absolute;
      top: 492px;
      right: -27px;

      img {
        width: 60%;
        height: auto;
      }
    }
  }
}

@media (min-width: 414px) {
  .auth-modal {
    &__card {
      width: 366px;
      height: 620px;
      top: 142px;
      border-radius: 5px;
      padding: 24px;
    }

    &__content {
      &-logo-ages {
        top: 184px;
        left: 84px;
      }

      &-logo-unibh {
        position: absolute;
        top: 262px;
        left: 218px;
        margin-left: 10px;

        img {
          width: 70%;
          height: auto;
        }
      }

      &-logo-unisul {
        top: 425px;
        left: 39px;
      }

      &-logo-faseh {
        top: 184px;
        left: 198px;
      }

      &-logo-unifacs {
        top: 341px;
        left: 30px;
      }

      &-logo-anhembi {
        top: 424px;
        left: 177px;
      }

      &-logo-sao-judas {
        top: 262px;
        right: 80px;
      }

      &-logo-unifg {
        top: 341px;
        right: -86px;
      }

      &-logo-unp {
        top: 508px;
        right: 3px;
      }
    }
  }
}

@media (min-width: 1080px) {
  .auth-modal {
    &__header {
      margin-top: 0;
    }

    &__header-title {
      width: 100%;
    }

    &__header-title-candidate {
      width: 100%;
    }

    &__card {
      width: 824px;
      height: 458px;
      top: 92px;
      padding: 38px;
    }

    &__content {
      &-logo-ages {
        top: 152px;
        left: 58px;

        img {
          width: 100%;
        }
      }

      &-logo-unibh {
        top: 256px;
        left: 48px;

        img {
          width: 100%;
        }
      }

      &-logo-unisul {
        top: 356px;
        left: 56px;

        img {
          width: 100%;
        }
      }

      &-logo-faseh {
        top: 152px;
        left: 308px;

        img {
          width: 100%;
        }
      }

      &-logo-unifacs {
        top: 254px;
        left: 252px;

        img {
          width: 100%;
        }
      }

      &-logo-anhembi {
        top: 356px;
        left: 252px;

        img {
          width: 100%;
        }
      }

      &-logo-sao-judas {
        top: 152px;
        right: 32px;

        img {
          width: 100%;
        }
      }

      &-logo-unifg {
        top: 256px;
        right: 34px;

        img {
          width: 100%;
        }
      }

      &-logo-unp {
        top: 356px;
        right: 32px;

        img {
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: 1366px) {
  .auth-modal {
    &__card {
      top: 158px;
    }
    
    &__header {
      margin-top: 0;
    }
  }
}
</style>

