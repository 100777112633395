<template>
  <button
    class="button-app"
    :class="[`button-app--${color}`, { 'button--disabled': disabled }]"
    :disabled="disabled"
    @click="clickHandler"
  >
    <template v-if="loading">
      <div class="loading">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </button>
</template>

<script>
export default {
  name: 'ButtonApp',
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    clickHandler() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.button-app {
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 8px 12px 8px 12px;
  height: 40px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.042px;

  &--primary {
    background-color: #2F54D3;
    color: white;
  }

  &--secondary {
    background-color: white;
    color: #2F54D3;
    border: 2px solid #2F54D3;
  }

  &--secondary:hover {
    background-color: #B8CEEF;
  }

  &--disabled {
    pointer-events: none;
  }
}

.loading {
	width: 100px;
	height: 50px;
	border-radius: 100%;
	position: relative;
  top: -17px;
	left: 0;
	align-items: center;
	justify-content: center;
	display: flex;
  margin-left: auto;
  margin-right: auto;
}
.loading > div {
	display: inline-flex;
	width: 10px;
	height: 10px;
	border-radius: 100%;
	background-color: #fff;
	margin: 0 5px;
	animation: anm-bl-3-move 1.5s infinite ease-in-out both;
}
.loading > div:nth-child(1) {
	animation-delay: -320ms;
}
.loading > div:nth-child(2) {
	animation-delay: -160ms;
}

@keyframes anm-bl-3-move {
	0%, 80%, 100% {
		transform: scale(0);
	}
	40% {
		transform: scale(1);
	}
}
</style>
