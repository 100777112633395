import api from '../api';

class AuthService {
  async login(params) {
    const { data } = await api.post("/api/Auth", params);
    return data;
  }

  async getLoginConfiguration() {
    const { data } = await api.get("/api/LoginConfiguration");
    return data;
  }

  async getInstitutionalEducations(params) {
    const { data } = await api.get("/api/InstitutionalEducations", { params });
    return data;
  }
}

export default new AuthService;