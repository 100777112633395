<template>
  <main class="main-page">
    <div class="main-card-all">
      <div class="fundo-erro">
        <div class="aguardando">
          <div class="card-all2">
            <div class="card2">
              <div class="img-logo2"></div>
            </div>
          </div>
        </div>
        <div class="aguarde">
          <div class="meditacao">
            <div class="titulo-aguarde">
              <p class="title-aguarde">Aguarde</p>
            </div>
            <div class="text-card">
              <p class="texto-aguarde">O Inspira não está disponível agora.</p>
              <p class="texto-aguarde" id="texto-aguardando">
                Tente novamente mais tarde ou recarregue a página
              </p>
              <button class="botao-aguarde" @click="reloadScreen()">
                Recarregar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "UnavailableService",
  methods: {
    reloadScreen() {
      this.$store.commit("auth/SET_LOGIN_ERROR", false);
      window.location.reload();
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

button {
  border-radius: 5px;
  border: 2px solid rgba(255, 255, 255, 0);
  background-color: #2f54d3;
  color: #fff;
  cursor: pointer;
}

button:hover {
  background-color: #2748b9;
}
.img-logo2 {
  background-image: url("../assets/images/meditando.svg") !important;
  width: 341px;
  height: 310px;
  margin-bottom: 33px;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
}

.main-page {
  background: linear-gradient(0deg, #e9ebee 65%, rgba(0, 0, 0, 0.05) 30% 65%);
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100% !important;
}

.main-card-all {
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.aguardando {
  display: flex;
  justify-content: center;
}

.aguarde {
  margin-left: -15px;
}

.titulo-aguarde {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.3px;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 28px;
}

.titulo-aguarde p:first-child {
  font-weight: 700;
}

.text-card {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.3px;
}

.text-card button {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 32px;
  line-height: 16px;
  letter-spacing: 0.042px;
  margin-top: 32px;
  width: 94px;
}


@media screen and (min-width: 320px) and (max-width: 359px) {
  .img-logo2 {
    background-size: contain;
    width: 300px;
  }
}

@media screen and (min-width: 320px) and (max-width: 1079px) {
  .aguarde {
    width: 100%;
  }
}

@media screen and (min-width: 360px) and (max-width: 1079px) {
  .img-logo2 {
    background-size: contain;
    height: 270px;
    width: 321px;
  }
}

@media screen and (max-width: 1079px) {
  .aguarde {
    margin-left: -20px;
  }

  .main-page {
    background: linear-gradient(0deg, #e9ebee 72%, rgba(0, 0, 0, 0.05) 30% 70%);
  }

  .main-card-all {
    top: 42%;
    left: 54%;
  }

  .texto-aguarde:nth-child(2) {
    width: 70%;
  }

  .text-card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
