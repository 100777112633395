<template>
  <div class="auth-login__form-unavailable">
    <div>
      <span v-html="message"></span>
    </div>
  </div>
</template>

<script>
  export default {
    name: "AuthLoginFormUnavailable",
    props: {
      message: {
        type: String,
        default: "",
      },
    },
    emits: ['show'],
    data () {
      return {
        showResultLink: true
      }
    },
    mounted() {
      let checkExist = setInterval(() => {
        const link = document.getElementById('linkModal');
        if (link) {
          link.addEventListener('click', (event) => {
            event.preventDefault();
            this.showModal();
          });

          clearInterval(checkExist);
        }
      }, 100);
    },
    methods: {
      showModal() {
        this.$emit('show', this.showResultLink);
      }
    }
  }
</script>

<style lang="scss" scoped>
.auth-login {
  &__form-unavailable {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    span {
      text-align: center;
      color: #4E61AC;
      line-height: 30px;
      font-size: 20px;
      font-weight: 400;

      strong, a {
        font-weight: 600;
      }
    }

    :nth-child(7), :nth-child(4) {
      margin-top: 32px;
    }

    span {
      display: block;
    }
  }
}
</style>