<template>
  <div class="alert-message-app">
    <div class="alert-message-app__error">
      <img src="../assets/icons/icon-error.svg" />
      <div v-if="message == 'requiredFields'" class="alert-message-app__text">
        <div>Atenção:</div>
        <div>Todos os campos são obrigatórios</div>
      </div>
      <div v-if="message == 'invalidFields'" class="alert-message-app__text">
        <div>CPF e/ou data <span class="alert-message-app__text-hide">de nascimento</span> incorretos</div>
        <div>ou você ainda não tem acesso</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlertMessageApp',
  props: ["message"]
};
</script>

<style lang="scss" scoped>
.alert-message-app {
  &__error {
    display: flex;
    gap: 10px;
    width: 256px;
  }

  &__text {
    color: #9F1B33;
    font-size: 12.64px;
    line-height: 15px;

    :first-child {
      margin-bottom: 3px;
    }
  }

  &__text-hide {
    display: none;
  }
}

@media (min-width: 1366px) {
.alert-message-app {
  &__text-hide {
    display: inline;
  }
}
}
</style>
