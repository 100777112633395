<template>
  <section class="registered-view">
    <div class="registered-view__logo-top">
      <img src="../assets/images/logo-inspira-base.svg" alt="Logo Inspira Medicina" />
    </div>
    <div class="registered-view__content">
      <div class="registered-view__card">
        <div class="registered-view__title">
          <span>Você já possui uma conta </span>
          <span>cadastrada!</span>
        </div>
        <div class="registered-view__description">
          <div>Realize o login na plataforma </div>
          <span>inserindo seu CPF </span>
          <span>e data de nascimento:</span>
        </div>
        <button-app
          class="registered-view__action"
          color="primary"
          @click="redirectLogin"
        >
          Ir para Login
        </button-app>
      </div>
    </div>
    <div class="registered-view__logo-footer">
      <img src="../assets/images/logo-inspira-small.svg" width="124" height="28" />
    </div>
  </section>
</template>

<script>
import { ButtonApp } from "@/components";
export default {
  name: "registeredView",
  components: {
    "button-app": ButtonApp
  },
  methods: {
    redirectLogin() {
      this.$router.push({ name: "home" });
    },
  },
}
</script>

<style lang="scss" scoped>
.registered-view {
  background: linear-gradient(90deg, #1F3268 0%, #6089D6 100%);

  &__logo-top {
    padding-top: 24px;
    display: flex;
    justify-content: center;
  }

  &__content {
    display: flex;
    justify-content: center;
  }

  &__card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 19px;
    margin-left: 24px;
    margin-right: 24px;
    width: 759px;
    height: 501px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__title {
    text-align: center;
    font-size: 20px;
    
    span {
      display: block;
    }
  }

  &__description {
    text-align: center;
    font-size: 16px;
    margin-top: 32px;
    margin-bottom: 32px;

    p {
      line-height: 24px;
    }

    span {
      display: block;
    }
  }

  &__action {
    width: 248px;
  }

  &__logo-footer {
    display: flex;
    justify-content: center;
    padding-top: 22px;
    padding-bottom: 18px;
  }
}

@media screen and (min-width: 414px) {
  .registered-view {
    &__card {
      width: 348px;
    }
  }
}

@media screen and (min-width: 1080px) {
  .registered-view {
    height: 100vh;
  
    &__card {
      width: 759px;
      height: 501px;
    }

    &__title {
      span {
        display: inline;
      }
    }

    &__description {
      span {
        display: inline;
      }
    }

    &__action {
      width: 300px;
    }
  }
}
</style>