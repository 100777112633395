import HomeView from "@/pages/HomeView.vue";
import RegisterView from "@/pages/RegisterView.vue";
import RegisteredView from "@/pages/RegisteredView.vue";

export const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/cadastrar",
    name: "cadastrar",
    component: RegisterView,
  },
  {
    path: "/cadastrada",
    name: "cadastrada",
    component: RegisteredView,
  }
];
