import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { auth } from "./modules";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    auth
  },
  plugins: [
    createPersistedState({
      key: "@Inspirali-Medicina",
      storage: window.sessionStorage,
    }),
  ],
});
