<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: "App",
  watch: {
    $route: {
      immediate: true,
      handler() {
        document.title = 'Inspira';
      }
    },
  },
  created() {
    (function(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
      var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-NVKXC6X7');
  },
  mounted() {
    let noscript = document.createElement('noscript');
    let iframe = document.createElement('iframe');

    iframe.setAttribute('src', 'https://www.googletagmanager.com/ns.html?id=GTM-NVKXC6X7');
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';
    iframe.width = '0';
    iframe.height = '0';

    noscript.appendChild(iframe);
    document.body.insertBefore(noscript, document.body.firstChild);
  }
};
</script>
