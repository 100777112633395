<template>
  <div class="auth-login-form">
    <toast-app ref="toast" />
    <div class="auth-login-form__header">
      <div class="auth-login-form__title">Participe do Inspira</div>
      <div class="auth-login-form__alert">
        <alert-message-app
          v-if="requiredFields"
          message="requiredFields"
        />
        <alert-message-app
          v-if="invalidFields"
          message="invalidFields"
        />
      </div>
    </div>
    <div class="auth-login-form__cpf">
      <label>CPF</label>
      <input-app
        v-model="userLogin"
        v-mask="'###.###.###-##'"
        type="text"
        placeholder="CPF"
        :error="$v.userLogin.$error || invalidFields"
        @input="invalidFields = false"
      />
    </div>
    <div class="auth-login-form__data">
      <label>Data de nascimento (dd/mm/aaaa)</label>
      <input-app
        v-model="birthDate"
        v-mask="'##/##/####'"
        type="text"
        placeholder="dd/mm/aaaa"
        :error="$v.birthDate.$error || invalidFields"
        @input="invalidFields = false"
      />
    </div>
    <div class="auth-login-form__action">
      <button-app
        color="primary"
        :loading="loading"
        :disabled="disabled"
        @click="submitForm"
      >
        Entrar
      </button-app>
      
    </div>
    <div v-if="canCreateAccount" class="auth-login-form__live">
      <router-link to="/cadastrar">
          <button-app
            color="secondary"
            class="auth-login-form__create-account"
          >
            Crie uma conta para ver a Live
          </button-app>
        </router-link>
    </div>
    <div v-else class="auth-login-form__help">
      <div class="auth-login-form__help-title">Problemas com seu acesso?</div>
      <div class="auth-login-form__help-content">
        <div>
          <span>Se você realizou a inscrição há mais de 48h, </span>
          <span>verifique se está inserindo CPF </span>
          <span>e data de nascimento utilizados </span>
          <span>para a inscrição no vestibular. </span>
        </div>
      </div>
    </div>
    <div class="auth-login-form__help-footer">
      <div>Em caso de dúvidas, entre em contato</div>
      <div>com a nossa <span class="auth-login-form__call-center" @click.stop="showModal">central de atendimento</span></div>
    </div>
  </div>
</template>

<script>
import { InputApp, ButtonApp, AlertMessageApp, ToastApp } from "@/components";
import { required, minLength, helpers } from "vuelidate/lib/validators";
import { format, parse } from 'date-fns';

export default {
  name: "AuthLoginForm",
  components: {
    "toast-app": ToastApp,
    "input-app": InputApp,
    "button-app": ButtonApp,
    "alert-message-app": AlertMessageApp,
  },
  props: ["canCreateAccount"],
  emits: ['show'],
  data() {
    return {
      userLogin: "",
      birthDate: "",
      loading: false,
      disabled: false,
      invalidFields: false,
      error: false,
      showResultLink: false
    };
  },
  validations: {
    userLogin: {
      required,
      minLength: minLength(14),
    },
    birthDate: {
      required,
      minLength: minLength(10),
      dateIsValid: helpers.withParams({ type: 'dateIsValid' }, value => {
        if (!value) return true
        const parsedDate = parse(value, 'dd/MM/yyyy', new Date())
        return parsedDate instanceof Date && !isNaN(parsedDate)
      })
    },
  },
  computed: {
    requiredFields() {
      return (this.$v.$invalid && this.$v.$dirty);
    }
  },
  methods: {
    showModal() {
      this.$emit('show', this.showResultLink);
    },
    dateFormat(value) {
      const parsedDate = parse(value, 'dd/MM/yyyy', new Date());
      return format(parsedDate, 'yyyy-MM-dd');
    },
    async submitForm() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        try {
          this.invalidFields = false;
          this.loading = true;
          this.disabled = true;
          
          const params = {
            userLogin: this.userLogin,
            birthDate: this.dateFormat(this.birthDate)
          }

          await this.$store.dispatch('auth/login', params);
          window.location.href = this.$store.getters['auth/userDetails'].authUlife
        } catch (err) {
          this.invalidFields = true;
        } finally {
          this.loading = false;
          this.disabled = false;
        }
      }
    },
  }
};
</script>

<style lang="scss" scoped>

label {
  color: rgba(0, 0, 0, 0.70);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.042px;
}

.auth-login-form {
  &__header {
    height: 86px;
  }

  &__title {
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-bottom: 28px;
  }

  &__cpf {
    margin-top: 12px;
    height: 78px;
    div {
      margin-top: 4px;
    }
  }

  &__data {
    height: 88px;
    div {
      margin-top: 4px;
    }
  }

  &__action {
    margin-bottom: 16px;
  }

  &__live {
    padding-top: 8px;
  }

  &__create-account {
    margin-top: 10px;
    margin-bottom: 40px;
  }

  &__help {
    text-align: center;
    font-size: 13px;

    &-title {
      font-weight: 700;
      margin-bottom: 4px;
    }

    &-content {
      display: flex;
      justify-content: center;
      margin-bottom: 12px;

      div {
        width: 214px;
      }
    }

    &-footer {
      font-size: 13px;
      text-align: center;
    }
  }

  &__call-center {
    color: rgba(47, 84, 211, 1);
    text-decoration: underline;
    cursor: pointer;
  }
}

@media (min-width: 414px) {
  .auth-login-form {
    &__help {
      &-title {
        font-weight: 700;
        margin-bottom: 4px;
      }

      &-content {
        display: flex;
        justify-content: center;
        margin-bottom: 8px;

        div {
          width: 284px;

          span {
            display: block;
          }
        }
      }
    }
  }
}

@media (min-width: 1080px) and (max-width: 1365px) {
  .auth-login-form {
    &__action {
      margin-bottom: 16px;
    }

    &__help {
      &-title {
        font-weight: 700;
        margin-bottom: 4px;
      }

      &-content {
        display: flex;
        justify-content: center;
        margin-bottom: 8px;
        padding-left: 14px;
        width: 228px;

        div {
          width: 284px;

          span {
            display: inline;
          }
        }
      }
    }
  }
}

@media (min-width: 1366px) {
  .auth-login-form {
    &__action {
      margin-bottom: 24px;
    }

    &__help {
      &-title {
        font-weight: 700;
        margin-bottom: 4px;
      }

      &-content {
        display: flex;
        justify-content: center;
        margin-bottom: 8px;

        div {
          width: 284px;

          span {
            display: block;
          }
        }
      }
    }
  }
}
</style>
