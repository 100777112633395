import AuthService from "../../services/auth/AuthService";

export const auth = {
  namespaced: true,
  state: () => ({
    userDetails: {},
    loginError: false
  }),
  mutations: {
    SET_USER_DETAILS(state, payload) {
      state.userDetails = payload;
    },
    SET_LOGIN_ERROR(state, payload){
      state.loginError = payload
    }
  },
  actions: {
    async login({ commit }, payload) {
      try {
        const { data } = await AuthService.login(payload);
        commit("SET_USER_DETAILS", data);
      } catch (err) {
        if(err.response.data.errors.errorMessage === 'Houve uma falha na autenticação com ULife'){
          commit("SET_LOGIN_ERROR", true);
        }
        throw new Error;
      }
    },
  },
  getters: {
    userDetails(state) {
      return state.userDetails;
    },
    getLoginError(state){
      return state.loginError
    }
  },
};
