<template>
  <div v-if="visible" class="toast" :class="type">
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      message: '',
      type: 'error'
    };
  },
  methods: {
    show(message, type = 'error', duration = 3000) {
      this.message = message;
      this.type = type;
      this.visible = true;
      setTimeout(() => {
        this.visible = false;
      }, duration);
    }
  }
};
</script>

<style>
.toast {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 10px;
  background-color: #f44336;
  color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s;
  text-align: center;
}
.toast.success {
  background-color: #4caf50;
}
.toast.error {
  background-color: #f44336;
}
</style>
