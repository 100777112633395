<template>
  <div>
    <input
      :type="type"
      :placeholder="placeholder"
      :value="value"
      :class="[{'input--error': error}]"
      :disabled="disabled"
      @input="updateValue($event.target.value)"
    />
  </div>
</template>

<script>
export default {
  name: 'InputApp',
  props: ["type", "placeholder", "value", "error", "disabled"],
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  width: 100%;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
  padding: 8px 12px 8px 12px;
  font-size: 16px;
  outline: none;
}

.input--error {
  border: 1px solid #9F1B33;
  border-radius: 5px;
}
</style>
