<template>
  <div class="register-form">
    <toast-app ref="toast" />
    <div class="register-form__title">
      <span>Complemente o seu </span>
      <span>cadastro para assistir </span>
      <span>a live do Inspira</span>
    </div>
    <div class="register-form__container">
      <div class="register-form__field">
        <label>Nome <span>*</span></label>
        <input-app
          v-model="form.name"
          type="text"
          placeholder="Nome"
          :disabled="loading"
          :error="$v.form.name.$error || invalidFields"
          @input="invalidFields = false"
        />
        <span v-if="!$v.form.name.required && $v.form.name.$dirty" class="register-form__error-message">
          <img src="../../assets/icons/icon-error.svg" width="14.22px" height="12.67px" /> Campo obrigatório
        </span>
      </div>
      <div class="register-form__field">
        <label>Sobrenome <span>*</span></label>
        <input-app
          v-model="form.lastname"
          type="text"
          placeholder="Sobrenome"
          :disabled="loading"
          :error="$v.form.lastname.$error || invalidFields"
          @input="invalidFields = false"
        />
        <span v-if="!$v.form.lastname.required && $v.form.lastname.$dirty" class="register-form__error-message">
          <img src="../../assets/icons/icon-error.svg" width="14.22px" height="12.67px" /> Campo obrigatório
        </span>
      </div>
      <div class="register-form__field">
        <label>CPF <span>*</span></label>
        <input-app
          v-model="form.cpf"
          v-mask="'###.###.###-##'"
          type="text"
          placeholder="CPF"
          :disabled="loading"
          :error="$v.form.cpf.$error || invalidFields"
          @input="invalidFields = false" 
        />
        <span v-if="!$v.form.cpf.required && $v.form.cpf.$dirty" class="register-form__error-message">
          <img src="../../assets/icons/icon-error.svg" width="14.22px" height="12.67px" /> Campo obrigatório
        </span>
        <span v-else-if="!$v.form.cpf.minLength && $v.form.cpf.$dirty" class="register-form__error-message">
          <img src="../../assets/icons/icon-error.svg" width="14.22px" height="12.67px" /> CPF inválido
        </span>
      </div>
      <div class="register-form__field">
        <label>Data nascimento (dd/mm/aaaa) <span>*</span></label>
        <input-app
          v-model="form.birthDate"
          v-mask="'##/##/####'"
          type="text"
          placeholder="dd/mm/aaaa"
          :disabled="loading"
          :error="$v.form.birthDate.$error || invalidFields"
          @input="invalidFields = false"
        />
        <span v-if="!$v.form.birthDate.required && $v.form.birthDate.$dirty" class="register-form__error-message">
          <img src="../../assets/icons/icon-error.svg" width="14.22px" height="12.67px" /> Campo obrigatório
        </span>
        <span v-else-if="!$v.form.birthDate.minLength && $v.form.birthDate.$dirty" class="register-form__error-message">
          <img src="../../assets/icons/icon-error.svg" width="14.22px" height="12.67px" /> Data nascimento inválida
        </span>
      </div>
      <div class="register-form__field">
        <label>E-mail <span>*</span></label>
        <input-app
          v-model="form.email"
          type="text"
          placeholder="E-mail"
          :disabled="loading"
          :error="$v.form.email.$error || invalidFields"
          @input="invalidFields = false"
        />
        <span v-if="!$v.form.email.required && $v.form.email.$dirty" class="register-form__error-message">
          <img src="../../assets/icons/icon-error.svg" width="14.22px" height="12.67px" /> Campo obrigatório
        </span>
        <span v-else-if="!$v.form.email.email && $v.form.email.$dirty" class="register-form__error-message">
          <img src="../../assets/icons/icon-error.svg" width="14.22px" height="12.67px" /> E-mail inválido
        </span>
      </div>
      <div class="register-form__field">
        <label>Celular <span>*</span></label>
        <input-app
          v-model="form.phone"
          v-mask="'(##) #####-####'"
          type="text"
          placeholder="(DDD) celular"
          :disabled="loading"
          :error="$v.form.phone.$error || invalidFields"
          @input="invalidFields = false"
        />
        <span v-if="!$v.form.phone.required && $v.form.phone.$dirty" class="register-form__error-message">
          <img src="../../assets/icons/icon-error.svg" width="14.22px" height="12.67px" /> Campo obrigatório
        </span>
        <span v-else-if="!$v.form.phone.minLength && $v.form.phone.$dirty" class="register-form__error-message">
          <img src="../../assets/icons/icon-error.svg" width="14.22px" height="12.67px" /> Celular inválido
        </span>
      </div>
      <div class="register-form__field register-form__field--full-width">
        <label>Em qual escola do ensino médio você estudou ou estuda?</label>
        <input-app
          v-model="form.schoolName"
          type="text"
          placeholder="Nome da escola"
          :disabled="loading"
        />
      </div>
      <div class="register-form__field">
        <label>Em qual estado está a sua escola?</label>
        <dropdown-app
          v-model="form.schoolUF"
          :items="states"
          :disabled="loading"
        />
      </div>
      <div class="register-form__field">
        <label>E em qual cidade?</label>
        <dropdown-app
          v-model="form.schoolCity"
          :items="cities"
          :loading="loadingCities"
          :disabled="loading"
        />
      </div>
      <div class="register-form__term-desktop">
        <span>Utilizamos os dados solicitados para entrar em contato sobre</span>
        <span>informações correlacionadas que podem ser de seu interesse.</span>
        <span>Você pode cancelar nossa comunicação a qualquer momento.</span>
      </div>
      <div class="register-form__actions">
        <button-app
          color="primary"
          class="register-form__button"
          :loading="loading"
          @click="submitForm"
        >
          Crie sua conta
        </button-app>
      </div>
      <div class="register-form__term-mobile">
        <span>Utilizamos os dados solicitados</span>
        <span>para entrar em contato sobre informações</span>
        <span>correlacionadas que podem ser</span>
        <span>de seu interesse. Você pode cancelar</span>
        <span>nossa comunicação a qualquer momento.</span>
      </div>
    </div>
  </div>
</template>

<script>
import RegisterService from "../../services/auth/RegisterService";
import { InputApp, ButtonApp, DropdownApp, ToastApp } from "@/components";
import { required, minLength, email, helpers } from "vuelidate/lib/validators";
import { parse } from 'date-fns';

export default {
  name: "RegisterForm",
  components: {
    "toast-app": ToastApp,
    "input-app": InputApp,
    "button-app": ButtonApp,
    "dropdown-app": DropdownApp
  },
  data() {
    return {
      loading: false,
      loadingCities: false,
      disabled: false,
      invalidFields: false,
      error: false,
      states: [],
      cities: [],
      form: {
        name: "",
        lastname: "",
        cpf: "",
        birthDate: "",
        email: "",
        phone: "",
        schoolName: null,
        schoolUF: null,
        schoolCity: null
      }
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      lastname: {
        required,
      },
      cpf: {
        required,
        minLength: minLength(14),
      },
      birthDate: {
        required,
        minLength: minLength(10),
        dateIsValid: helpers.withParams({ type: 'dateIsValid' }, value => {
          if (!value) return true
          const parsedDate = parse(value, 'dd/MM/yyyy', new Date())
          return parsedDate instanceof Date && !isNaN(parsedDate)
        })
      },
      email: {
        required,
        email
      },
      phone: {
        required,
        minLength: minLength(14),
      },
    }
  },
  computed: {
    requiredFields() {
      return (this.$v.$invalid && this.$v.$dirty);
    }
  },
  watch: {
    'form.schoolUF': {
      handler (value) {
        if (value) {
          this.form.schoolCity = null;
          this.cities = [];
          this.fetchCities(value.id);
        }
      },
      
    }
  },
  mounted() {
    this.fetchStates();
  },
  methods: {
    async fetchStates() {
      await RegisterService.getStates()
        .then((res) => {
          this.states = res.data.map((state) => ({
            id: state.value,
            label: state.description
          })); 
        })
    },
    async fetchCities(uf) {
      this.loadingCities = true;
      await RegisterService.getCities(uf)
        .then((res) => {
          this.cities = res.data.map((city) => ({
            id: city.value,
            label: city.description
          }));
        })
        .finally(() => {
          this.loadingCities = false;
        });
    },
    async submitForm() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.loading = true;
        const params = {
          ... this.form,
          schoolUF: this.form.schoolUF ? this.form.schoolUF.id : null,
          schoolCity: this.form.schoolCity ? this.form.schoolCity.id : null
        };

        await RegisterService.createUser(params)
          .then((res) => {
            if (res.data.isValid) {
              window.location.href = res.data.redirectLink
            } else {
              this.$router.push({ name: "cadastrada" });
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.register-form {
  padding: 24px 32px;

  &__title {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 32px;

    span {
      display: block;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 32px;
  }

  &__field {
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: 92px;

    label > span {
      color: #B5001C;
    }
  }

  &__field--full-width {
    grid-column: 1 / -1;
    margin-bottom: 24px;
  }

  &__button {
    width: 100%;
  }

  &__actions {
    align-items: center;
    flex-direction: row;
    margin-bottom: 24px;
  }

  &__term-mobile {
    display: block;
    line-height: 16.88px;
    text-align: center;
    font-size: 12px;

    span {
      display: block;
    }
  }

  &__term-desktop {
    display: none;
  }

  &__error-message {
    color: #890417;
    font-size: 14.22px;
    line-height: 14.22px;
  }
}

@media (min-width: 1080px) {
  .register-form {
    &__container {
      grid-template-columns: 1fr 1fr;
    }

    &__field--full-width {
      margin-bottom: 0;
    }

    &__term-mobile {
      display: none;
    }

    &__term-desktop {
      display: block;
      line-height: 16.88px;
      text-align: center;
      font-size: 12px;

      span {
        display: block;
      }
    }

    &__title {
      span {
        display: inline;
      }
    }

    &__actions {
      margin-top: 8px;
      margin-bottom: 0;
    }
  }
}
</style>